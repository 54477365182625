import request from '@/utils/request'

export function getZoneConfig (storeId) {
    return request({
        url: '/customer-service/lrHomeSection/queryLrHomeSection?storeId=' + storeId,
        method: 'post'
    })
}

export function saveZoneConfig (data) {
    return request({
        url: '/customer-service/lrHomeSection/saveLrHomeSection',
        method: 'post',
        data
    })
}