<template>
    <div class="pad-container">

        <!-- 上面展示图片 -->
        <div>
            <div class="flx-row ali-c flx-wr imgWrap js-btw">
                <div
                        v-for="(item,index) in act_form.list"
                        :key="index"
                        class="flx-col ali-c js-c mb-30 mr-50"
                >
                    <img :src="picsPath(item.pictureUrl)" v-if="item.pictureUrl" class="el-image mb-15"/>
                    <el-image v-else>
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <div>{{item.name}}</div>
                </div>
            </div>
            <div class="info fs-16 mt-10 mb-10">首页金刚区推荐提示</div>
        </div>

        <!-- 下面提交配置信息 -->
        <el-tabs v-model="activePart" @tab-click="changeTab" class="mt-20">
            <el-tab-pane
                    :label="shop.storeName"
                    :name="String(shop.id)"
                    v-for="(shop,index) in shopList"
                    :key="index"
            >
                <el-form
                        class="flx-row ali-c flx-wr js-btw el-form mt-24"
                        label-width="80px"
                        :ref="'act_form'+(shop.id)"
                        :model="act_form"
                >
                    <div v-for="(item,index) in act_form.list" :key="index" class="zone pad-container">
                        <div class="ml-10 mb-20">金刚{{index+1}}区</div>
                        <!-- :prop="'list.'+index+'.pictureUrl'" -->
                        <!-- :rules="[{message:'商品主图不能为空',required: true,trigger: 'blur'}]" -->
                        <el-form-item label="名称" :prop="'list['+index+'].name'"
                                      :rules="[{message: '名称不能为空',required: true,trigger: 'blur'}]">
                            <el-input v-model="item.name"/>
                        </el-form-item>
                        <el-form-item label="图片上传" :prop="'list['+index+'].pictureUrl'"
                                      :rules="[{message: '图片不能为空',required: true,trigger: 'blur'}]">
                            <UploadPics
                                    :uploadType="'main'"
                                    :imgLimit="1"
                                    imgSize="112*112"
                                    :imgsPreview="item.pictureUrl"
                                    @getImgData="(x,y,z)=>getImgData(x,y,z,index)"
                                    :key="index"
                            />
                        </el-form-item>
                        <!-- :prop="'list.'+index+'.jumpLink'" -->
                        <!-- :rules="[{message:'跳转链接不能为空',required: true,trigger: 'blur'}]" -->
                        <el-form-item label="跳转方式">
                            <el-radio-group v-model="item.jumpType" style="width:100%">
                                <el-radio :label="0">内部页面</el-radio>
                                <el-radio :label="1">其他小程序</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="appid" v-if="item.jumpType == 1">
                            <el-input v-model="item.appid" placeholder="请输入目标小程序的appid"/>
                        </el-form-item>
                        <el-form-item label="跳转链接" :prop="'list['+index+'].jumpLink'"
                                      :rules="[{message: '跳转链接不能为空',required: true,trigger: 'blur'}]">
                            <el-input v-model="item.jumpLink" placeholder="请输入跳转链接"/>
                            <!-- <el-select v-model="item.jumpLink" clearable>
                              <el-option
                                v-for="(item,index) in activities"
                                :key="index"
                                :label="item.activityName"
                                :value="item.id"
                              ></el-option>
                            </el-select> -->
                        </el-form-item>
                    </div>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <div class="flx-row ali-c js-fe mt-50">
            <el-button class="form-button" type="primary" @click="submit">保 存</el-button>
        </div>
    </div>
</template>
<script>
    import html2canvas from "html2canvas";
    import UploadPics from "@/components/UploadPics";
    import {getStoreList} from "@/api/online_department_store/commodity_manage"
    import {getZoneConfig, saveZoneConfig} from "@/api/config_center/miniprogram_kingKong";
    import {getActivityList} from "@/api/market/activity";

    export default {
        components: {UploadPics},
        data() {
            return {
                img: "",
                dataurl: "",
                dialogVisible: false,
                activePart: "1",
                shopList: [],
                act_form: {
                    list: [
                        {
                            pictureUrl: "",
                            jumpLink: "",
                            section: "1",
                            name: "",
                            appid: null,
                            jumpType: 0
                        },
                        {
                            pictureUrl: "",
                            jumpLink: "",
                            section: "2",
                            name: "",
                            appid: null,
                            jumpType: 0
                        },
                        {
                            pictureUrl: "",
                            jumpLink: "",
                            section: "3",
                            name: "",
                            appid: null,
                            jumpType: 0
                        },
                        {
                            pictureUrl: "",
                            jumpLink: "",
                            section: "4",
                            name: "",
                            appid: null,
                            jumpType: 0
                        },
                        {
                            pictureUrl: "",
                            jumpLink: "",
                            section: "5",
                            name: "",
                            appid: null,
                            jumpType: 0
                        },
                        {
                            pictureUrl: "",
                            jumpLink: "",
                            section: "6",
                            name: "",
                            appid: null,
                            jumpType: 0
                        },
                        {
                            pictureUrl: "",
                            jumpLink: "",
                            section: "7",
                            name: "",
                            appid: null,
                            jumpType: 0
                        },
                        {
                            pictureUrl: "",
                            jumpLink: "",
                            section: "8",
                            name: "",
                            appid: null,
                            jumpType: 0
                        }
                    ]
                },
                activities: [],
            };
        },
        mounted() {
            if (this.$store.getters.storeId) {
                this.activePart = this.$store.getters.storeId;
                this.getZoneConfigHandle();
            } else {
                this.getStores();
            }
        },
        methods: {
            // 获取活动列表
            getActivityList() {
                return new Promise((resolve, reject) => {
                    let params = {
                        storeId: this.activePart,
                        pageNum: 1,
                        pageSize: 9999
                    };
                    getActivityList(params)
                        .then(res => {
                            let data = res.data.body;
                            this.activities = data.list.filter(item => item.status == 1);
                            resolve();
                        })
                        .catch(err => {
                            reject();
                        });
                });
            },
            //获取门店列表
            getStores() {
                getStoreList().then(res => {
                    this.shopList = res.data.body.list;
                    this.activePart = res.data.body.list[0].id + "";
                    this.getZoneConfigHandle();
                })
            },
            getZoneConfigHandle() {
                getZoneConfig(this.activePart).then(res => {
                    let data = res.data.body
                    if (data.length > 0) {
                        // console.log(data)
                        data.forEach((item) => {
                            this.$set(this.act_form.list, (parseInt(item.section) - 1), item)
                        })
                    }
                })
            },
            submit() {
                let key = "act_form" + this.activePart;
                let form = this.$refs[key][0];
                this.submitValid(form, this.doSubmit);
            },

            submitValid(form, cb) {
                form.validate(valid => {
                    if (!valid) {
                        this.$message.warning("表单信息不完整（有误）");
                        return;
                    } else {
                        cb();
                    }
                });
            },

            doSubmit() {
                let data = JSON.parse(JSON.stringify(this.act_form.list))
                // console.log(data)
                data.forEach(item => {
                    item.storeId = this.activePart;
                })
                saveZoneConfig(data).then(res => {
                    this.$message.success("保存成功");
                });
            },
            // 获取图片数据
            getImgData(imgData, type, params, imgIndex) {
                if (type == "main") {
                    let data = this.act_form.list;
                    data[imgIndex].pictureUrl = imgData.mainImg;
                    this.$set(data, imgIndex, data[imgIndex]);
                }
            },
            // 切换tab
            changeTab() {
                this.act_form.list.forEach(item => {
                    item.pictureUrl = "";
                    item.jumpLink = "";
                    item.name = "";
                });
                this.getZoneConfigHandle();
            }
        }
    };
</script>
<style scoped lang='scss'>
    .imgWrap {
        width: 750px;
        margin: 0 auto;
        color: #666;
        font-weight: normal;
        font-size: 15px;
    }

    .info {
        text-align: center;
    }

    .el-form {
        padding: 0 10px 10px;
        box-sizing: border-box;
        width: 100%;
    }

    .zone {
        width: 48%;
        margin-top: 20px;
    }

    .capture {
        width: 300px;
    }

    .el-image {
        border-radius: 50%;
        width: 112px;
        height: 112px;
    }
</style>